<template>
  <v-app>
      <right-menu v-if="showIt" />
      <div style="margin-inline-start: 250px;margin-inline-end: 50px;margin-top:50px;" v-if="showIt">
        <router-view />
      </div>
      <div v-else>
        <router-view />
      </div>
    
    <License />
  </v-app>
</template>

<script>
import Vue from 'vue';
import License from "./components/License.vue"

import axios from 'axios'
import RightMenu from './components/newDesign/RightMenu.vue';

export default Vue.extend({
  name: 'App',
  components: {License, RightMenu},
  data: () => ({
    drow:false,
    connected:false,
    withsidemenu:true,
  }),
 
  computed: {
      lang: function(){
        if(this.$store.state.deflang == 'en'){
          return this.$store.state.lang.en;
        }else{
          return this.$store.state.lang.ar;
        }
      },
      showIt(){
        console.log(this.$route);
        if(this.$route.path != '/' && this.$route.path != '/Login' && this.$route.path != '/login'){
          return true
        }else{
          return false
        }
      }
  },
  
  methods:{
    getStart(){
      let pp = new FormData();
      let type = 'getMeTodo';
      pp.append('type',type);
      axios.post(
          this.$store.state.SAMCOTEC.r_path
          ,pp
      ).then((response) => {
          this.$store.commit('setLicenseType',response.data.results.data);
          this.$store.state.redirectURl = response.data.results.loginRedirect;
          localStorage.setItem('_ECar_',JSON.stringify(response.data.results.data));
          localStorage.setItem('redirectURl',JSON.stringify(response.data.results.redirectURl));
          
      })
      
    },
    handleUpdate: function () {
      //this.connected = true;
    },
    getCookies(){
      if(this.connected == true) return false;
      let x = this.$cookies.get(this.$store.state.COOKIEPhase);
      if(x == null){
        
        this.connected = false;
        if(this.$route.name != 'Login')
          this.$router.push({name: "Login"})
      }
      else{
        this.connected = true;
        if(this.$route.name == 'Login')
          this.$router.push({name: 'Home'});
      }
    },
    checkLicense(){
      axios.get(
        this.$store.state.SAMCOTEC.r_path
      ).then( (response) => {
        
        const res = response.data;
        if(res.error.number == 555){
          // document.getElementById('apply_license').style.display = "block";
          // this.license = res.results.data[1];
          // this.settings = res.results.data[0];
        }
      })
    },
   
  },
  created() {
    setTimeout(() => {this.getStart();},1000);
    document.title = this.$store.state.SystemName;
    //this.getCookies()
  },
});
</script>
<style>
.v-text-field{
  padding-top:0px !important;
  margin-top:0px !important;
  font-size:.5em !important;
  background:#f0fbff !important;
}
.v-data-table>.v-data-table__wrapper>table>tbody>tr>td, .v-data-table>.v-data-table__wrapper>table>tfoot>tr>td, .v-data-table>.v-data-table__wrapper>table>thead>tr>td {
    font-size: .875rem;
    height: 10px !important;
}
.v-data-table-header__icon{
  display:none !important;
}
.outsearch{
  color:green;
  border:1px solid #ccc;
}
thead th{
  background: #7aa5da !important;
  color:#000 !important;
  border:1px solid #eee !important;
  font-size:12px;
  white-space: nowrap;
}
thead th a , thead th i, thead th i a{
  color:#FFF !important;
  white-space: nowrap;
}
tbody td{
  font-size:12px;
  color:#000 !important;
  white-space: nowrap;
  border:1px solid #ccc;
}
.v-breadcrumbs{
  padding:12px !important;
}
.v-tab--active{
  background:#ccc;
  color:#FFF;
}
.v-tabs-slider-wrapper a{
  color:#FFF !important;
}
tfoot th{
  border:1px solid #ccc;
}
.close svg {
    width: 24px !important;
    height: 24px !important;
    color: #FFF;
    background: red;
    border-radius: 50%;
}
header button.close {
    width: 24px !important;
    height: 24px !important;
}
.snotify-centerCenter{
  width:700px;
  left: calc(50% - 700px/ 2);
}
</style>