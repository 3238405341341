<template>
    <div class="menuMain">
        <div class="topHeader" v-if="menuList.length > 0">
        </div>
        <div class="rightMenu" v-if="menuList.length > 0">
            <div class="doItNow" style="margin-bottom:30px;">
                <div id="MyClockDisplay" class="clock" onload="showTime()">{{ time }}</div>
                <div class="dateRow">{{ (new Date()).getFullYear() - 1 }} - {{ (new Date()).getMonth() + 1 }} - {{ (new Date()).getDate() }}</div>
            </div>
            <div class="menuList" id="menu_calendar" @click="goTo('/CRMCalendar','menu_calendar')">الاعمال اليومية <!--<div :class="`triangle ${lang.lalgin}Pos`"></div>--></div>
            <div class="menuList" :id="`menu_${item.id}`" v-for="(item,index) in menuList" :key="index" @click="goTo(item.url,`menu_${item.id}`)">{{ item[`name_${lang.langname}`] }} 
                <!-- <div :class="`triangle ${lang.lalgin}Pos`"></div> --></div>
            <div class="menuList">{{ lang.training }} <!--<div :class="`triangle ${lang.lalgin}Pos`"></div>--></div>
            <div class="menuList">{{ lang.myInfo }} <!--<div :class="`triangle ${lang.lalgin}Pos`"></div>--></div>
            <div class="menuList" style="background:red !important;">{{ lang.logout }} <!--<div :class="`triangleRed ${lang.lalgin}Pos`"></div>--></div>

            <div class="closeTraining">
                <div class="text-center">{{lang.username}} {{ myUsername }}</div>
            </div>
        </div>
        <div class="leftSide" v-if="menuList.length > 0">
        </div>
        <div class="bottomSide" v-if="menuList.length > 0">
        </div>
    </div>
</template>

<script>

import axios from 'axios'
export default{
    data() {
        return {
            myUsername: 'Admin',
            time: '10:10:10 AM',
            menuList: [
            ],
            
        }
    },
    computed: {
        lang: function ()
        {
            if(this.$cookies.get(this.$store.state.LangCooki) != null){
                if(this.$cookies.get(this.$store.state.LangCooki)  == 'en'){
                return this.$store.state.lang.en;
                }else{
                return this.$store.state.lang.ar;
                }
            }
            else if(this.$store.state.deflang == 'en'){
                return this.$store.state.lang.en;
            }else{
                return this.$store.state.lang.ar;
            }
        },
    },
    methods: {
        getMenu(){
            
            const post = new FormData();
            post.append("type","getMenu");
            post.append("auth",this.$cookies.get(this.$store.state.COOKIEPhase));
            post.append('data[main]',0)
            axios.post(
                this.$store.state.SAMCOTEC.r_path,post
            ).then((response) => {
                if(response && response.data && response.data.results){
                    this.menuList = response.data.results.menuList
                    this.myUsername = response.data.results.myUsername
                }
            })
        },
        goTo(url,id){
            
            const doc = document.querySelectorAll('[id^="menu_"]');
            for(let i=0;i<doc.length;i++){
                console.log("menues" ,doc[i].id);
                if(doc[i].id !== id){
                    document.getElementById(doc[i].id).classList.remove('active')
                }
                else{
                    document.getElementById(doc[i].id).classList.add('active')
                }
            }
            
            this.$router.push({path: url})
        },
        showTime(){
            var date = new Date();
            var h = date.getHours(); // 0 - 23
            var m = date.getMinutes(); // 0 - 59
            var s = date.getSeconds(); // 0 - 59
            var session = "AM";
            
            if(h == 0){
                h = 12;
            }
            
            if(h > 12){
                h = h - 12;
                session = "PM";
            }
            
            h = (h < 10) ? "0" + h : h;
            m = (m < 10) ? "0" + m : m;
            s = (s < 10) ? "0" + s : s;
            
            var time = h + ":" + m + ":" + s + " " + session;
            this.time = time;
            
            setTimeout(this.showTime, 1000);
        }
    },
    created() {
        this.showTime();
        this.getMenu();
0
    }
}

</script>
<style scoped>
.menuMain{
    position:fixed;
    top:0;
    right:0;
    bottom:0;
}
.dateRow{
    font-size: 14px;
}
.doItNow{
    margin-top:20px;
    width:90%;
    background:#ccc;
    text-align:center;
    border:2px solid #fff;
}
.clock {
    text-align: center;
    color: #000000;
    font-size: 24px;
    font-family: Orbitron;
    letter-spacing: 2px;
}
.topHeader{
    position:fixed;
    top:0;
    left:0;
    right:0;
    margin:0;
    padding:10px;
    background:rgb(215, 177, 0);
    border: 1px solid rgb(215, 177, 0);
    color:#000;
}
.leftSide{
    position:fixed;
    padding:10px;
    background:rgb(215, 177, 0);
    border: 1px solid rgb(215, 177, 0);
    color:#000;
    top:0;
    left:0;
    bottom: 0;
}
.bottomSide{
    position:fixed;
    padding:15px;
    background:rgb(215, 177, 0);
    border: 1px solid rgb(215, 177, 0);
    color:#000;
    right:0;
    left:0;
    bottom: 0;
}
.rightMenu{
    padding-inline-start: 10px;
    position:relative;
    display:flex;
    flex-direction: column;
    background:rgb(215, 177, 0);
    border: 1px solid rgb(215, 177, 0);
    color:#000;
    height:calc(100vh - 20px);
    width:220px;
    align-items: start;
}

.menuList {
    position: relative;
    margin: 5px 5px 5px 5px;
    background: #000;
    color: #fff;
    padding:10px 5px;
    width: 180px;
    border-top: 2px solid #fff;
    border-bottom: 2px solid #fff;
    border-inline-start: 2px solid #fff;
    border-inline-end: 2px solid #fff;
    text-align:center;
    cursor: pointer;
}
.active{
    background:rgb(255, 255, 255) !important;
    color: #000 !important;
}
.triangle {
   position: absolute;
   top:0px;
   width: 0px;
   height: 0px;
   border-style: solid;
   border-width: 20px 16px 20px 0;
   border-color: transparent #000000 transparent transparent;
   transform: rotate(0deg);
}
.triangleRed {
   position: absolute;
   top:0px;
   width: 0px;
   height: 0px;
   border-style: solid;
   border-width: 20px 16px 20px 0;;
   border-color: transparent red transparent transparent;
   transform: rotate(0deg);
}
.leftPos{
    left:-15px;
}
.rightPos{
    right:-20px;
}
.menuList:hover{ 
    background:#333;
}
.closeTraining{
    position:absolute;
    bottom: 10px;
    left: 5px;
    right: 5px;
}
</style>
