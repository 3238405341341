<template>
    <div>
    </div>
</template>

<script>
export default {
    components: {},
    data() {
        return {
          
        }
    },
    created() {
       //
    },

    methods: {
        
    },
    computed: {
        lang: {
            get: function(){
                if(this.$cookies.get(this.$store.state.LangCooki) != null){
                    if(this.$cookies.get(this.$store.state.LangCooki)  == 'en'){
                        return this.$store.state.lang.en;
                    }else{
                        return this.$store.state.lang.ar;
                    }
                }
                else if(this.$store.state.deflang == 'en'){
                    return this.$store.state.lang.en;
                }else{
                    return this.$store.state.lang.ar;
                }
            },
            set: function(newValue){
                return newValue
            }
        },
          
    },
    
}
</script>
<style>
.helpicon_ar{
    position:absolute;
    left: 10px;
    top:50px;
    width:80px;
    display:flex;
    justify-content: center;
    justify-items: center;
    background:#000;
    color:#fff;
    border-radius: 25px;
    padding:2px;
    z-index: 1000;
    cursor: pointer;
}
.helpicon_en{
    position:absolute;
    right: 10px;
    top:30px;
    width:80px;
    display:flex;
    justify-content: center;
    justify-items: center;
    background:#000;
    color:#fff;
    border-radius: 25px;
    padding:2px;
    z-index: 1000;
    cursor: pointer;
}
.gbackground{
    position: relative;
    z-index: 10;
    background: rgb(215,177,0);
    background: linear-gradient(
    -45deg, rgba(215,177,0,1) 0%, 
    #d7b100 88%, rgba(0,0,0,1) 88%, 
    rgba(0,0,0,1) 89%, rgba(215,177,0,1) 89%, 
    rgba(215,177,0,1) 91%, rgba(0,0,0,1) 91%, 
    rgba(0,0,0,1) 92%, rgba(215,177,0,1) 92%, 
    rgba(215,177,0,1) 94%, rgba(0,0,0,1) 94%, 
    rgba(0,0,0,1) 95%, rgba(215,177,0,1) 95%, 
    rgba(215,177,0,1) 100%
    );
    padding:0px !important;
}
.logoutclass{
    color:#FFF;
    position: absolute;
    left:5px;
    top: 10px;
}
.clearMe_ar{
    position: relative;
    background: rgb(215,177,0);
    direction:rtl;
    color:#FFF;
    padding:2px 10px 10px 5px;
}
.clearMe_en{
    position: relative;
    background: rgb(215,177,0);
    direction:ltr;
    color:#FFF;
    padding:2px 10px 10px 5px;
}
.v-sheet.v-card:not(.v-sheet--outlined) {
    box-shadow: none;
}
.imglogo{
    position: absolute;
    top:2px;
    left:3px;
    z-index: 1000;
    height:85px;
    border: 2px solid #000;
}
td, th{
    border:1px solid #000 !important;
}
.timeNow_ar{
    direction: ltr;
    display:flex;
    flex-direction: row;
    justify-content: center;
    justify-items: center;
    align-items: center;
    background:#000;
    color:gold;
    font-size:1rem;
    position: absolute;
    top:30px;
    left: 30px;
    padding:10px;
    /* border-bottom-right-radius: 8px;
    border-bottom-left-radius: 8px; */
    width:200px;
    height: 30px;
    z-index: 1000;
    box-shadow: rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px;
}
.timeNow_en{
    direction: ltr;
    display:flex;
    flex-direction: row;
    justify-content: center;
    justify-items: center;
    align-items: center;
    background:#000;
    color:gold;
    font-size:1rem;
    position: absolute;
    top:30px;
    right: 30px;
    padding:10px;
    /* border-bottom-right-radius: 8px;
    border-bottom-left-radius: 8px; */
    width:200px;
    height: 30px;
    z-index: 1000;
    box-shadow: rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px;
}
</style>