<template>
  <div class="">
    <header-portrate />
    <div id="mainRow" style="">
   
      <v-row class="mainrow m-2" :style="`direction:`+lang.dir+`;margin-top:-20px !important;`">
        <mainTabsComp :tabs="tabs" />
      </v-row>

    </div>
    <Footer />
  </div>
</template>

<script>
  import mainTabsComp from '@/components/mainTabsComp.vue';
import HeaderPortrate from '@/components/Header-Portrate-menu.vue';
// import HeaderPortrate from '@/components/Header-Portrate.vue';
  export default {
    name: 'MenuHome',

    components: {
        mainTabsComp,
        HeaderPortrate,
        
    },
    data() {
      return {
        
      }
    },
    created() {
      //this.menuStyle()
    },
    methods: {
      menuStyle(){
        console.log(this.$refs.RightMenu);
        if(this.$refs.RightMenu.menuList.length == 0){
          document.getElementById('mainRow').style.marginInlineStart = "0"
        }else{
          document.getElementById('mainRow').style.marginInlineStart = "250px"
        }
      },
    },
    computed: {
        lang: function(){
            if(this.$cookies.get(this.$store.state.LangCooki) != null){
                if(this.$cookies.get(this.$store.state.LangCooki)  == 'en'){
                return this.$store.state.lang.en;
                }else{
                return this.$store.state.lang.ar;
                }
            }
            else if(this.$store.state.deflang == 'en'){
                return this.$store.state.lang.en;
            }else{
                return this.$store.state.lang.ar;
            }
        },
        
      items:function() {
          let title = this.$store.state.hometitle;
          const lang = this.$cookies.get(this.$store.state.LangCooki);
          if(lang == "en"){
            title = this.$store.state.hometitle_en;
          }
          return {
            text: title,
            disabled: true,
            href: '/home',
          }
      },
      
      tabs: function() {
          let id = this.$route.params.id;
          if(typeof id === 'undefined') id = 1;
          const menu = this.$store.getters['state'].menu;
          const license = this.$store.getters['state'].licenseType.activemenu;
        
          const alicense = this.$store.getters['state'].licenseType
          let currentMenu = menu[license];
          if(!license){
            return false;
          }
          let cMenu = {main: {},sub:[]};
          Object.values(currentMenu).forEach(value =>{
            let cM = localStorage.getItem('currentMenu');
            //   console.log("license", license)
            if(typeof this.$route.params.id !== 'undefined'){
                cM = this.$route.params.id;
                localStorage.setItem('currentMenu',cM);
            }
            if(value.main.index == cM){
                cMenu = value.sub;
            }else if(cM == null && value.main.index == 1){
                cMenu = value.sub;
            }
          });
      
          let t = []
          Object.values(cMenu).forEach(value =>{
          
              if(this.$route.path == value.href){
                  value.class = "mytab";
              }else{
                  value.class = "mytab4";
              }
              if(this.lang.langname == 'ar'){
                  value.name = value.arname
              }else{
                  value.name = value.enname
              }
              for(let i =0; i< value.groupid.length;i++){
                  if(value.groupid[i] == this.$store.getters['state'].group_id){
                  if(alicense[value.perms[0]] || value.perms[0] == 'any')
                      t.push(value)
                  }
              }
          
          })
      
          return t;
      }
    },
  }
</script>
<style>
.v-data-table-header__icon{
  display:none !important;
}
.outsearch{
  color:green;
  border:1px solid #ccc;
}
thead th, tfoot th, .tfoot th{
  background: #c3d9ff !important;
  color:#000 !important;
  border:1px solid #000 !important;
  font-size:12px;
  white-space: nowrap;
}
thead th a , thead th i, thead th i a{
  color:#FFF !important;
  white-space: nowrap;
}
tbody td{
  font-size:12px;
  color:#000 !important;
  white-space: nowrap;
  border:1px solid #000;
}
.v-breadcrumbs{
  padding:12px !important;
}
.v-tab--active{
  background:#ccc;
  color:#FFF;
}
.v-tabs-slider-wrapper a{
  color:#FFF !important;
}
tfoot th{
  border:1px solid #000;
}
.close svg {
    width: 74px !important;
    height: 34px !important;
    color: #FFF;
    background: red;
}
header button.close {
    width: 24px !important;
    height: 24px !important;
}
input{
  box-shadow: none !important;
}
button:active,button:focus{
  box-shadow: none !important;
}
._mybtn{
  font-size:.8rem;
  border:0px solid #fff;
  width:100%;
}
._mybtn2{
  font-size:.8rem;
  background:rgb(15, 212, 230) !important;
  border:0px solid #000;
  width:100%;
}
._mybtn4{
  font-size:.8rem;
  background:rgb(15, 90, 230) !important;
  border:0px solid #fff;
}
._mybtn3{
  font-size:.8rem;
  background:#198754 !important;
  border:0px solid #fff;
}
._mybtn1{
  font-size:.8rem;
  background:#fe7b33 !important;
  border:0px solid #fff;
}
td,th{
      height: 30px !important;
}
.v-data-table > .v-data-table__wrapper > table > tbody > tr > td, .v-data-table > .v-data-table__wrapper > table > tbody > tr > th, .v-data-table > .v-data-table__wrapper > table > thead > tr > td, .v-data-table > .v-data-table__wrapper > table > thead > tr > th, .v-data-table > .v-data-table__wrapper > table > tfoot > tr > td, .v-data-table > .v-data-table__wrapper > table > tfoot > tr > th{
  padding: 0 10px !important;
}
.v-data-table > .v-data-table__wrapper > table > tbody > tr > td, .v-data-table > .v-data-table__wrapper > table > tbody > tr > th, .v-data-table > .v-data-table__wrapper > table > thead > tr > td, .v-data-table > .v-data-table__wrapper > table > thead > tr > th, .v-data-table > .v-data-table__wrapper > table > tfoot > tr > td, .v-data-table > .v-data-table__wrapper > table > tfoot > tr > th {
    padding: 0 10px !important;
}
.viewBTN{
  font-size:.8rem;
  background:blue !important;
  width:100%;
  color:#fff !important;
  height:25px !important;
  margin-left:5px;
  text-align:center;
  display:flex;
  justify-content: center;
  justify-items: center;
  align-items: center;
}
.viewBTN:hover,.viewBTN:active,.viewBTN.viewBTN:click{
  background:blue !important;
  width:100%;color:#fff !important;
}
.backBlue{
  background: blue !important;
  color: #fff !important;
  border-bottom: 1px solid #fff !important;
  border-left: 1px solid #fff !important;
  border-right: 1px solid #fff !important;
}
.backGold{
  background: gold !important;
  color: #000 !important;
  border-bottom: 1px solid #fff !important;
  border-left: 1px solid #fff !important;
  border-right: 1px solid #fff !important;
}
.viewBTN{
  font-size:.8rem;
  background:blue !important;
  width:100%;
  color:#fff !important;
  height:25px !important;
  margin-left:5px;
  text-align:center;
  display:flex;
  justify-content: center;
  justify-items: center;
  align-items: center;
}
.viewBTN:hover,.viewBTN:active,.viewBTN.viewBTN:click{
  background:blue !important;
  width:100%;color:#fff !important;
}
.loadingPage{
  position: fixed;
  left:0;
  right:0;
  top:0;
  bottom: 0;
  display: flex;
  justify-content: center;
  justify-items: center;
  align-items: center;
  background:#ffffff50;
  z-index: 100000;
}
</style>

